@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("LibreBaskerville"),
    url("./fonts/LibreBaskerville-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("LibreBaskerville"),
    url("./fonts/LibreBaskerville-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Italic"; /*Can be any text*/
  src: local("LibreBaskerville"),
    url("./fonts/LibreBaskerville-Italic.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Regular", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  background-color: #d3d3d3;
  color: #ababab;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.hidden {
  display: none;
}
.colored-line {
  position: relative;
  margin-top: 2.5rem;
  margin-left: 2rem;
  height: 0.2rem;
  width: 1.8rem;
  background-color: #5d6472;
}
